<template>
  <div class="title_container">
    <div class="retangulo"></div>
    <h1 class="title">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: ["title"]
};
</script>

<style lang="scss">

.title_container {
  display: flex;
  align-items: center;
}
  
.v-application .title {
  margin-left: 16px;
  font-family: "Raleway";
  font-size: 24px !important;
  font-weight: 700;
  line-height: 28px;
  color: #334666;
  text-transform: uppercase;
}
  
.retangulo {
  display: inline-block;
  width: 9px;
  height: 31px;
  border-radius: 1px;
  background-color: #334666;
}
  
</style>